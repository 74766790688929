@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Rouben-SemiBold', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Rouben-Bold";
  src: url('./fonts/RoubenBold/font.woff2') format('woff2'), url('./fonts/RoubenBold/font.woff') format('woff');
}
@font-face {
  font-family: 'Rouben-SemiBold';
  src: url('./fonts/RoubenSemiBold/font.woff2') format('woff2'), url('./fonts/RoubenSemiBold/font.woff') format('woff');
}
@font-face {
  font-family: "Rouben-Regular";
  src: url('./fonts/RoubenRegular/font.woff2') format('woff2'), url('./fonts/RoubenRegular/font.woff') format('woff');
}
@font-face {
  font-family: "Rouben-Medium";
  src: url('./fonts/RoubenMedium/font.woff2') format('woff2'), url('./fonts/RoubenMedium/font.woff') format('woff');
}

h1{
  font-size: 1.2em;
  max-width: 450px;
  margin: auto;
}

h1:after{
  width: 200px;
  height: 2px;
  background-color: #1d70b7;
  content: " ";
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  margin: auto;
  margin-top: 20px;
}

h2{
  color: #abbce2;
  text-transform: uppercase;
  border-bottom: 2px solid #abbce2;
  font-size: 1.2em;
  margin-top: 60px;
  margin-bottom: 20px;
}

.content{
  width: 100%;
  max-width: 800px;
}

.bg{
  top: 20px;
  left: 20px;
}

p.more{
  font-family: 'Rouben-Regular', sans-serif;
  border-top: 1px solid #1d70b7;
  padding-top: 20px;
  margin-top: 50px;
  a{
    color: #1d70b7;
    display: inline-block;
    position: relative;
  }
  a:after{
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: #1d70b7;
    visibility: hidden;
    -moz-transform: scaleX(0);
    -webkit-transform: scaleX(0);
    -o-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
    -moz-transition: all .3s ease-in-out 0s;
    -webkit-transition: all .3s ease-in-out 0s;
    -o-transition: all .3s ease-in-out 0s;
    -ms-transition: all .3s ease-in-out 0s;
    transition: all .3s ease-in-out 0s;
  }
  a:hover:after{
    visibility: visible;
    -moz-transform: scaleX(1);
    -webkit-transform: scaleX(1);
    -o-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1);
  }
}

p.generallink {
  font-family: 'Rouben-Regular', sans-serif;
  a{
    color: #1d70b7;
    display: inline-block;
    position: relative;
  }
  a:after{
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: #1d70b7;
    visibility: hidden;
    -moz-transform: scaleX(0);
    -webkit-transform: scaleX(0);
    -o-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
    -moz-transition: all .3s ease-in-out 0s;
    -webkit-transition: all .3s ease-in-out 0s;
    -o-transition: all .3s ease-in-out 0s;
    -ms-transition: all .3s ease-in-out 0s;
    transition: all .3s ease-in-out 0s;
  }
  a:hover:after{
    visibility: visible;
    -moz-transform: scaleX(1);
    -webkit-transform: scaleX(1);
    -o-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1);
  }
}

hr{
  height: 1px;
  background-color: #1d70b7;
  width: 100%;
  border: 0px;
  margin-top: 50px;
  margin-bottom: 20px;
}

.versicherungssumme {

  .full{
      margin: 70px 0px 0px 0px;
      width: 30%;
      text-align: center;
      background-color: #ffffff;
      box-shadow: 0px 0px 6px #dedbe2;
      border-radius: 4px;

      .head{
          display: block;
          background-color: #f1f3fb;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          padding: 10px;
          color: #1d70b7;
          font-size: 1em;
          text-transform: uppercase;
          position: relative;
          transition: all 0.5s ease-in-out;
          p{
            position: relative;
            z-index: 1;
          }
          span{
            font-family: 'Rouben-Bold', sans-serif;
            font-size: 1.5em;
          }
      }
      a:hover .head{
          color: #ffffff;
          transition: all 0.5s ease-in-out;
      }
      a.selected .head{
          color: #ffffff;
          background: rgb(39,53,130);
          background: linear-gradient(-90deg, rgba(39,53,130,1) 20%, rgba(29,112,183,1) 85%);
      }
      a .head:after{
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: rgb(39,53,130);
        background: linear-gradient(-90deg, rgba(39,53,130,1) 20%, rgba(29,112,183,1) 85%);
        transition: all 0.5s ease-in-out;
        content: " ";
        -webkit-transition: opacity 0.5s ease;
        -moz-transition: opacity 0.5s ease;
        -o-transition: opacity 0.5s ease;
        transition: all 0.5s ease;
        z-index: 0;
        opacity: 0;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        }
    a:hover .head:after {
            webkit-transition: opacity 0.5s ease;
            -moz-transition: opacity 0.5s ease;
            -o-transition: opacity 0.5s ease;
            transition: all 0.5s ease;
            opacity: 1;
    }
      .inside{
        font-family: 'Rouben-Regular', sans-serif;
        padding: 20px;
        p:first-child{
            color: #1d70b7;
            font-family: 'Rouben-Medium';
            border-bottom: 1px solid #f1f3fb;
            padding-bottom: 20px;
            margin-bottom: 20px;
          }
        p:nth-child(2){
          font-size: 0.8em;
        }
      }
  }
}

p.explanation{
  color: #1d70b7;
  font-family: 'Rouben-Regular', sans-serif;
  font-size: 0.8em;
  margin-top: 20px;
  margin-bottom: 20px;
}

.toggler,
.accordion__button{
  font-family: 'Rouben-Regular', sans-serif;
  color: #1d70b7;
  border-bottom: 1px solid #1d70b7;
  padding: 10px 10px 10px 0px;
  position: relative;
}
.toggler:before,
.accordion__button:before{
    vertical-align: middle;
    display: inline-block;
    content: '';
    height: 7px;
    width: 7px;
    margin-right: 12px;
    border-bottom: 2px solid #1d70b7;
    border-right: 2px solid #1d70b7;
    transform: rotate(-45deg);
    transition: transform .3s ease-in-out 0s;
    transform-origin: center;
    margin-top: -4px;
}
.accordion__button:hover::before{
  transform: rotate(45deg);
  transform-origin: center;
  transition: transform .3s ease-in-out 0s;
}
.accordion__button[aria-expanded='true']::before{
  transform: rotate(45deg);
  transform-origin: center;
}

.accordion__panel{
font-family: 'Rouben-Regular', sans-serif;
padding: 20px 20px;
background-color: #f1f3fb;
border-bottom-left-radius: 4px;
border-bottom-right-radius: 4px;
}
.bg-formbg{
  background-color: #dae0f3;
}
.form{
  margin-top: 70px;
  margin-bottom: 40px;

  label.input{
    font-family: 'Rouben-Regular', sans-serif;
    span{
      font-family: 'Rouben-Medium', sans-serif;
      color: #1d70b7;
      text-transform: uppercase;
      font-size: 0.8em;
      letter-spacing: 2px;
    }
    span.info{
        text-transform: none;
        color: #000;
        letter-spacing: 0;
        display: block;
        font-family: 'Rouben-Regular', sans-serif;
    }
  }
  label.checkbox{
    position: relative;
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    input{
      margin-top: 4px;
    }
    span{
      font-family: 'Rouben-Regular', sans-serif;
      display: block;
      margin-left: 18px;
      padding-left: 7px;
      text-align: left;
    }
    span::before {
      content: "";
      display: block;
      position: absolute;
      width: 18px;
      height: 18px;
      top: 3px;
      left: 0;
      background: #dae0f3;
      border: 0px;
      border-radius: 2px;
    }
    span::after {
      display: block;
      position: absolute;
      width: 18px;
      height: 18px;
      top: 3px;
      left: 0;
      opacity: 0;
      content: url('../public/assets/img/check.svg');
      transition: .3s opacity ease;
    }
  }
  input[type="checkbox"] {
    opacity: 0;
    z-index: -1;
    position: absolute;
  }
  input[type="checkbox"]:checked + span::after {
    background:#1d70b7;
    border-radius: 2px;
    transition: .3s opacity ease;
    opacity: 1;
  }
  input.error + span:before {
    border: 1px solid rgb(215, 0, 0);
    border-radius: 2px;
  }

  fieldset{
    legend{
      color: #1d70b7;
      width: 100%;
      padding-top: 40px;
      margin-bottom: 20px;
    }
    legend:after{
      width: 100%;
      display: block;
      content: "";
      height: 2px;
      background-color: #1d70b7;
    }
  }

  input.error {
    -webkit-box-shadow: inset 0px 0px 0px 1px rgb(215, 0, 0); 
    box-shadow: inset 0px 0px 0px 1px rgb(215, 0, 0);
  }

    a{
      font-family: 'Rouben-Regular', sans-serif;
      color: #1d70b7;
      display: inline-block;
      position: relative;
      width: max-content;
      font-size: 0.8em;
    }
    a:after{
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      bottom: 0;
      left: 0;
      background-color: #1d70b7;
      visibility: hidden;
      -moz-transform: scaleX(0);
      -webkit-transform: scaleX(0);
      -o-transform: scaleX(0);
      -ms-transform: scaleX(0);
      transform: scaleX(0);
      -moz-transition: all .3s ease-in-out 0s;
      -webkit-transition: all .3s ease-in-out 0s;
      -o-transition: all .3s ease-in-out 0s;
      -ms-transition: all .3s ease-in-out 0s;
      transition: all .3s ease-in-out 0s;
    }
    a:hover:after{
      visibility: visible;
      -moz-transform: scaleX(1);
      -webkit-transform: scaleX(1);
      -o-transform: scaleX(1);
      -ms-transform: scaleX(1);
      transform: scaleX(1);
    
  }

}

[type='checkbox']{
  color: #1d70b7;
}
div.language{
  max-width: 800px;
  padding: 30px 0px 0px 0px;
  margin: auto;
  text-align:right;
  position: relative;
  color: #fff;
  font-size: 0.9em;
  font-family: 'Rouben-Regular', sans-serif;
  button.language{
    color: #fff;
    padding: 0px 0px;
    margin: 0px 8px;
    display: inline-block;
    position: relative;
  }
  button.language:after{
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    background: none;
    background-color: #fff;
    visibility: hidden;
    transform: scaleX(0);
    transition: all .3s ease-in-out 0s;
    top: auto;
    bottom: 0;
    
  }
  button.language.inactive:hover:after{
    visibility: visible;
    -moz-transform: scaleX(1);
    -webkit-transform: scaleX(1);
    -o-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1);
  }
}
.steps{
  max-width: 840px;
  padding: 30px 20px 0px 20px;
  margin: auto;
  position: relative;
  display: flex;
  justify-content: center;
  li{
    width: 20%;
    text-align:center;
    position: relative;
    color: #ffffff;
    a{
      color: #ffffff;
      font-size: 0.8em;
      text-transform: uppercase;
      display: contents;
      letter-spacing: 1px;
      font-family: 'Rouben-Regular';
      position: relative;
      margin: auto;
      display: flex;
    align-items: center;
    }
    a.inactive span{
      width: 100%;
      max-width: 30px;
       height: 30px;
       border-radius: 100%;
      border: 1px solid #5a97cb;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #5a97cb;
      }
    a.active span{
      width: 100%;
      max-width: 30px;
      height: 30px;
      background-color: #1d70b7;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      border: 1px solid #fff;
    }
    a:before{
      width: 55px;
      display: block;
      content: "";
      background-color: #1d70b7;
      height:2px;
      position: relative;
      float: left;
      margin-right: 10px;
    }
    a:after{
      width: 55px;
      display: block;
      content: "";
      background-color: #1d70b7;
      height:2px;
      position: relative;
      float: right;
      margin-left: 10px;
    }
    a span.complete{
      background-image: url('../public/assets/img/complete.svg');
      background-repeat: no-repeat;
      background-size: contain;
      font-size: 0em;
      background-color:#1d70b7;
    }
    
  }
  li.leer:before{
    width: 100%;
    display: inline-block;
    content: "";
    background-color: #1d70b7;
    height: 2px;
    position: relative;
    vertical-align: middle;
    margin-top: 2px;
  }

}


.stepsbtn{
  padding: 20px 0px 30px 0px;
  max-width: 800px;
  margin: auto;
    li{
      color: #fff;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-family: 'Rouben-Medium', sans-serif;
      font-size: 0.8em;
      margin-left: 30px;
      margin-right: 30px;
      min-width: 15%;
      text-align: center;
    }
    li.next{
      color: #5a97cb;
      position: relative;
      transition: all 0.3s ease-in-out;
    }
    li.next:after{
      vertical-align: middle;
      display: inline-block;
      content: '';
      height: 6px;
      width: 6px;
      margin-left: 8px;
      border-bottom: 2px solid #5a97cb;
      border-right: 2px solid #5a97cb;
      transform: rotate(-45deg);
      margin-top: -3px;
      transition: all 0.3s ease-in-out;
    }
    li.next:hover{
      color: #fff;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
    }
    li.next:hover:after{
      border-color: #fff;
      transition: all 0.3s ease-in-out;
    }
    li.prev{
      color: #5a97cb;
      transition: all 0.3s ease-in-out;
    }
    li.prev:before{
      vertical-align: middle;
      display: inline-block;
      content: '';
      height: 6px;
      width: 6px;
      margin-right: 8px;
      border-bottom: 2px solid #5a97cb;
      border-left: 2px solid #5a97cb;
      transform: rotate(45deg);
      margin-top: -2px;
      transition: all 0.3s ease-in-out;
    }
    li.prev:hover{
      color: #fff;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
    }
    li.prev:hover:before{
      border-color: #fff;
      transition: all 0.3s ease-in-out;
    }
}
button,
a.buttonstyle {
  margin-left: auto;
  display: flex;
  padding: 6px 35px 6px 20px;
  letter-spacing: 1px;
  transition: all 0.3s ease-in-out;
  position: relative;
  font-family: 'Rouben-Medium', sans-serif;
  max-width: 290px;
  span{
    position:relative;
    z-index: 1;
    font-weight: normal;
    font-size: 0.9em;
    line-height: 1.4;
  }
  svg{
    right: 10px;
    position: absolute;
    transition: all 0.3s ease-in-out;
  }
}

button:hover,
a.buttonstyle:hover{
  transition: all 0.3s ease-in-out;
  svg{
    right: 5px;
    transition: all 0.3s ease-in-out;
    z-index: 1;
  }
}

button:after,
a.buttonstyle:after {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: rgb(39,53,130);
  background: linear-gradient(-90deg, rgba(39,53,130,1) 20%, rgba(29,112,183,1) 85%);
  transition: all 0.5s ease-in-out;
  content: " ";
  -webkit-transition: opacity 0.5s ease;
  -moz-transition: opacity 0.5s ease;
  -o-transition: opacity 0.5s ease;
  transition: all 0.5s ease;
  z-index: 0;
  opacity: 0;
  border-radius: 4px;
  }
button:hover:after,
a.buttonstyle:hover:after {
      webkit-transition: opacity 0.5s ease;
      -moz-transition: opacity 0.5s ease;
      -o-transition: opacity 0.5s ease;
      transition: all 0.5s ease;
      opacity: 1;
}

button.change, a.buttonstyle.change {
  position: relative;
  padding-left: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  background: none;
  color: #1d70b7;
  padding-right: 10px;
  img{
    width: 30px;
    height: 30px;
    margin-right: 7px;
    position: relative;
    z-index: 1;
  }
  span{
    transition: color 0.5s ease-in-out;
  }
}
button.change:hover span, 
a.buttonstyle.change:hover span {
  color: #ffffff;
  transition: color 0.5s ease-in-out;
}

button.download,
a.download{
  position: relative;
  margin-right: auto;
  display: grid !important;
  color: white;
  padding: 10px 20px 4px 20px;
  text-align: center;
  img{
    width: 40px;
    height: 40px;
    margin:  10px auto;
    position: relative;
    z-index:1;
  }
  span{
    font-size: 1em;
  }
}
button.cancel{
border: 1px solid #1d70b7;
}
button.continue{
  border: 1px solid #1d70b7;
}
button.cancel:hover{
  background-color: #dae0f3;
}
button.cancel:hover:after{
  display: none;
}

a.soon:hover{
  background: none !important;
  background-color: #dae0f3 !important;
  cursor: default;
}
a.soon:after{
  display: none !important;
}

a.soon img{
  opacity: 0.3;
}
a.soon{
  position: relative;
}
a.soon:before{
  content: "coming soon";
  position: absolute;
  left: 0;
  right: 0;
  top: 0px;
  padding: 4px;
  background-color: #1d70b7;
  font-family: 'Rouben-Medium', sans-serif;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
[data-lang='de'] a.soon:before {
  content: 'Bald verfügbar';
}
img.sm{
  display: none;
}
img.selected {
  display: none !important;
}
a:hover, a.selected {
  img.selected {
    display: block !important;
  }
  img.notselected {
    display: none !important;
  }
}

.manual{
  margin: 0px;
  background-color: #f1f3fb;
  padding: 20px;
  padding-left: 40px;
  font-family: 'Rouben-Regular', sans-serif;
  position: relative;
  font-size: 0.9em;
  display: block;
  width: 100% !important;
&:first-of-type {
  margin: 40px 0px 0px 0px;
}
:before{
  content: " ";
  width: 20px;
  height: 20px;
  position: absolute;
  background-image: url('../public/assets/img/info.svg');
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 4px;
  background-color: #1d70b7;
  left: 10px;
  top: 10px;
}
  strong{
    font-family: 'Rouben-SemiBold', sans-serif;
    color: #1d70b7;
  }
}

p.info{
  margin: 40px 0px 0px 0px;
  background-color: #f1f3fb;
  padding: 20px;
  padding-left: 40px;
  font-family: 'Rouben-Regular', sans-serif;
  position: relative;
  font-size: 0.9em;
}
p.info:before{
  content: " ";
  width: 20px;
  height: 20px;
  position: absolute;
  background-image: url('../public/assets/img/info.svg');
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 4px;
  background-color: #1d70b7;
  left: 10px;
  top: 10px;
}

.loading{
  margin-top: -4px;
  height: 4px;
  background-color: #c0c4db;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  div{
    height: 4px;
    background: rgb(29,112,183);
    background: linear-gradient(45deg, rgba(29,112,183,1) 15%, rgba(40,53,131,1) 84%);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

#spinner {
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: rgba(29,112,183,0.9);
    top: 0;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
  .spinnercontainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    p.processinfo{
      color: #fff;
      text-align: center;
      margin: 30px 0px 10px 0px;
      padding: 0px 20px;
      width: 100%;
      font-family: 'Rouben-Regular', sans-serif;
      font-size: 0.8em;
    }
  }  
}

button .lds-ring,
a.buttonstyle .lds-ring {
  margin: auto;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 115px;
  height: 115px;
  background-image: url('../public/assets/img/bs-icon.svg');
background-repeat: no-repeat;
background-size: 40px;
background-position: center 40px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 100px;
  height: 100px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

input.success{
  background-image: url('../public/assets/img/success.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center right;
  padding-right: 50px;
}

p.processinfo{
  color: #1d70b7;
  margin: 10px 0px 10px 0px;
  font-family: 'Rouben-Regular', sans-serif;
  font-size: 0.8em;

  &.error {
    color: rgb(215, 0, 0);
  }
}


.unterlagen{
  h1{
      margin-bottom: 40px;
  }
  p{
    span{
      color:#1d70b7;
      font-size: 1.2em;
      line-height: 2em;
    }
    strong{
      font-family: 'Rouben-Medium', sans-serif;
      font-weight: normal;
    }
  }
  p:last-child{
    font-family: 'Rouben-Regular', sans-serif;
  }
}
          
a.selected {
    background: rgb(39,53,130);
    background: linear-gradient(-90deg, rgba(39,53,130,1) 20%, rgba(29,112,183,1) 85%);
    border-radius: 4px;
}

div.summary{
  padding: 20px 10px;
  background-color: #f1f3fb;
  margin-top: 40px;
  font-family: 'Rouben-Regular', sans-serif;
  span.heading {
    font-family: 'Rouben-SemiBold';
    color: #1d70b7;
  }
  .table{
    display: grid;
    grid-template-columns: repeat(2, 0fr);
    gap: 4px 20px;
    align-items: baseline;
    border-top: 2px solid #fff;
    padding-top: 30px;
    border-bottom: 2px solid #fff;
    padding-bottom: 30px;
  }
}

.dialog h3{
  background-color: #1d70b7;
  color: #fff;
  margin: auto;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Rouben-Regular', sans-serif;
  font-size: 1em;
  letter-spacing: 1px;
  display: block;
  width: 100%;
  padding: 15px 10px;
  margin-bottom: 30px;
}
.dialog p{
  font-size: 1.0em;
  font-family: 'Rouben-Medium', sans-serif;
  margin-bottom: 30px;
  padding: 0px 10px;
}
.dialog .btn-container{
  justify-content: center;
}

.fontinherit {
  font-size: inherit !important;
}

@media (max-width: 1025px) {
  .bg{
    width: 50px;
    top: 20px;
    left: 20px;
  }
}

@media (max-width: 650px) {
  .content{
    padding: 40px 20px;
    .versicherungssumme{
      flex-wrap: wrap;
      .full{
        width: 100%;
        margin-top: 30px;
      }
    }
    .form{
      margin-top: 40px;
    }
  }
}
@media (max-width: 550px) {
  .stepsbtn{
    justify-content: space-between;
    li{
      margin: 0px 5px;
    }
    li.prev{
      margin-left: 20px;
    }
    li.next{
      margin-right: 20px;
    }
  } 
  .steps li {
    width: 35%;
  }
  .steps li:first-child{
    display: none;
  }
  .steps li:last-child{
    display: none;
  }
  .steps li a{
    justify-content: center;
  }
  .steps li a:after{
    width: 60px;
  }
  .steps li a:before{
    width: 60px;
  }
  img.sm{
    display: block;
    width: 40px;
    height: 40px;
    margin-right: 20px;
  	}
    img.lrg{
      display: none;
      }
      a.soon{
        padding-top: 50px !important;
    }
}

@media (max-width: 500px) {
  .bg{
    width: 50px;
    top: 20px;
    left: 20px;
  }
}

@media (max-width: 370px) {
  .stepsbtn{
    li.prev{
      font-size: 0em;
    }
    li.prev:before{
      margin-top: 6px;
    }
    li.next{
      font-size: 0em;
    }
    li.next:after{
      margin-top: 6px;
    }
  } 
}